<!--
 * @Author: your name
 * @Date: 2021-04-19 18:30:28
 * @LastEditTime: 2021-05-19 14:58:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\sub\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation>
      <span @click="showshezhi = true" class="sz">设置</span>
    </navigation>
    <div class="mmc">
      <div class="classmsg">
        <div>
          <div class="pms">
            <div>{{ allmsg.name }}</div>
            <div :style="allmsg.politics?'':'visibility: hidden;margin:0 0'">{{ allmsg.politics }}</div>
            <img
              v-if="(allmsg.sex + '').indexOf('男') != -1"
              src="../../assets/sex_1.png"
              alt=""
            />
            <img
              v-if="(allmsg.sex + '').indexOf('女') != -1"
              src="../../assets/sex_2.png"
              alt=""
            />
          </div>
          <div class="phmsg">
            <div>
              {{
                allmsg.job_status == 1
                  ? "离职 随时到岗"
                  : allmsg.job_status == 2
                  ? "在职-月内到岗"
                  : allmsg.job_status == 3
                  ? "在职-考虑机会"
                  : allmsg.job_status == 4
                  ? "在职，暂不考虑"
                  : "获取失败"
              }}
            </div>
            <div>
              手机:
              {{
                (allmsg.mobile + "").substr(0, 3) +
                "****" +
                (allmsg.mobile + "").substr(7)
              }}
            </div>
          </div>
        </div>
        <div class="userclass">
          <img
            v-if="(allmsg.sex + '').indexOf('男') != -1"
            src="../../assets/user_1.png"
            alt=""
          />
          <img
            v-if="(allmsg.sex + '').indexOf('女') != -1"
            src="../../assets/user_2.png"
            alt=""
          />
        </div>
      </div>
      <div class="zhicla">
        <div>
          {{
            allmsg.entry_status == 1
              ? "未入职"
              : allmsg.entry_status == 2
              ? "已入职"
              : allmsg.entry_status == 3
              ? "已离职"
              : allmsg.entry_status == 4
              ? "拒绝入职"
              : allmsg.entry_status == 5
              ? "离职确认中"
              : "获取失败"
          }}
        </div>
        <div class="imgcc">
          <div>
            <img src="../../assets/mm1.png" alt="" />{{
              allmsg.years_working
            }}年
          </div>
          <div>
            <img src="../../assets/mm2.png" alt="" />{{
              allmsg.education_background
            }}
          </div>
          <div><img src="../../assets/mm3.png" alt="" />{{ age }}</div>
        </div>
      </div>
      <div v-if="allmsg.province != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>户籍所在地</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.province }}</div>
          <div class="mocla"></div>
        </div>
      </div>
      <div v-if="allmsg.email != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>联系邮箱</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">
            {{ allmsg.email == "" ? "无" : "****************" }}
          </div>
          <div class="mocla"></div>
        </div>
      </div>
      <div v-if="allmsg.industry != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>意向行业</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.industry }}</div>
          <div class="mocla"></div>
        </div>
      </div>
      <div class="allgw">
        <div class="gw1">
          <div></div>
          <div>求职岗位</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.jop }}，{{ allmsg.province }}</div>
          <div class="mocla">{{ allmsg.salary }}</div>
        </div>
        <div class="gw3">期望行业：{{ allmsg.industry }}</div>
      </div>
      <div v-if="educational_experience != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>教育院校及专业</div>
        </div>
        <div class="scc">
          <div
            v-for="(item, index) in educational_experience"
            v-bind:key="index"
            class="jingcla"
          >
            <div>
              <div>{{ item.school }}</div>
              <div class="timeclass">
                {{ item.start_time }}
              </div>
            </div>
            <div>{{ item.education_background }} {{ item.major }}</div>
          </div>
        </div>
      </div>
      <div v-if="allmsg.politics != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>政治面貌</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.politics }}</div>
          <div class="mocla"></div>
        </div>
      </div>
      <div v-if="allmsg.years_working != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>工作年限</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.years_working }}</div>
          <div class="mocla"></div>
        </div>
      </div>
      <div v-if="allmsg.province != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>求职区域</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.province }}</div>
          <div class="mocla"></div>
        </div>
      </div>
      <div v-if="qiyemsg != ''" class="alljingcla">
        <div class="gw1 jingl">
          <div></div>
          <div>工作经历</div>
        </div>
        <div>
          <div
            v-for="(item, index) in qiyemsg"
            v-bind:key="index"
            class="jingcla"
          >
            <div>
              <div>{{ item.company_name }}</div>
              <div class="timeclass">{{ item.entry_time }}至{{ item.quit_time }}</div>
            </div>
            <div>{{ item.position }}</div>
            <mytextarea stylemsg="border:none;" :index="index" :readonlymsg="true" :msg="item.working_experience"></mytextarea>
          </div>
        </div>
      </div>
      <div v-if="allmsg.honor != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>职业资格证书/荣誉奖项</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.honor }}</div>
          <div class="mocla"></div>
        </div>
      </div>
      <div class="jnclat">
        <div class="gw1">
          <div></div>
          <div>技能云</div>
        </div>
        <!-- <div @click="$router.push('/addskill')"> -->
        <div @click="toskillmeth">
          <van-icon color="#006AFF" size=".39rem" name="add-o" />
          <div class="addji">综合技能</div>
        </div>
      </div>
      <div class="jin">
        <div @click="jimeth(0)" :class="jiindex == 0 ? 'selcl' : ''">
          工作技能
        </div>
        <div @click="jimeth(1)" :class="jiindex == 1 ? 'selcl' : ''">
          生活技能
        </div>
        <div @click="jimeth(2)" :class="jiindex == 2 ? 'selcl' : ''">
          综合技能
        </div>
      </div>
      <div class="duana">
        <!-- <div class="duana2" v-show="skillindex == 0">
          <div v-for="(item, index) in skillList.work" v-bind:key="index">
            {{ item.skill_name }}
          </div>
        </div> -->
        <div v-show="skillindex == 0">
          <div
            class="duana2"
            v-if="skillList.work && skillList.work.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.work"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.work && skillList.work.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
        <div v-show="skillindex == 1">
          <div
            class="duana2"
            v-if="skillList.lift && skillList.lift.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.lift"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.lift && skillList.lift.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
        <div v-show="skillindex == 2">
          <div
            class="duana2"
            v-if="skillList.other && skillList.other.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.other"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.other && skillList.other.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
      </div>
    </div>
    <div class="nomsgclass"></div>
    <van-action-sheet
      v-model="showshezhi"
      :actions="actions"
      cancel-text="取消"
      description="状态设置"
      close-on-click-action
      @select="selectshezhi"
    />
  </div>
</template>
<script>
import nomsgvue from "../../components/nomsg/index";
import getage from "../../../util/age"
export default {
  components: {
    nomsgvue,
  },
  data() {
    return {
      jiindex: "0",
      showshezhi: false,
      actions: [],
      isshoucang: false, //是否收藏过
      allmsg: "", //数据
      skillList: "", //工作技能
      skillindex: 0, //工作技能下标
      qiyemsg: "",
      age: "", //年龄
      educational_experience: "",
    };
  },
  created() {
    this.$http
      .post("/firm/v1/resume/details", {
        reqType: "resume",
        report_id: atob(this.$route.query.id),
      })
      .then((res) => {
        this.allmsg = JSON.parse(res.data).data.resumeDetail;
        this.skillList = JSON.parse(res.data).data.skillList;
        if (this.allmsg.entry_status == 3) {
          this.actions = [{ name: "入职", entry_status: 2 }];
        } else if (this.allmsg.entry_status == 2) {
          this.actions = [{ name: "离职", entry_status: 3 }];
        } else {
          this.actions = [{ name: "获取失败", entry_status: "" }];
        }
        if (this.allmsg.work_resume != "") {
          this.qiyemsg = JSON.parse(this.allmsg.work_resume);
        } else {
          this.qiyemsg = "";
        }
        //教育经历
        if (this.allmsg.educational_experience != "") {
          this.educational_experience = JSON.parse(
            this.allmsg.educational_experience
          );
           
        } else {
          this.educational_experience = "";
        }
        //年龄
        this.age = getage(this.allmsg.birthday,this.allmsg.age);

        this.allmsg.is_collect == 1
          ? (this.isshoucang = true)
          : (this.isshoucang = false);
      });
  },
  methods: {
    //技能云
    toskillmeth(){
      this.$router.push({
        path:"/skilllist",
        query:{
          phone:btoa(this.allmsg.mobile)
        }
      })
    },
    /**
     * 收藏简历
     */
    shoucangmeth() {
      if (this.isshoucang == false) {
        this.$http
          .post("/firm/v1/Resume/collect", {
            reqType: "resume",
            report_id: this.allmsg.id,
          })
          .then((res) => {
            this.isshoucang = !this.isshoucang;
             
            this.$toast(JSON.parse(res.data).msg);
          });
      } else {
        this.$http
          .post("/firm/v1/Resume/collectDel", {
            reqType: "resume",
            report_id: this.allmsg.id,
          })
          .then((res) => {
            this.isshoucang = !this.isshoucang;
            this.$toast(JSON.parse(res.data).msg);
          });
      }
    },
    /**
     * 获取联系方式
     */
    getmsgmeth() {
      this.$router.push({
        path: "evalpay",
        query: {
          msg: JSON.stringify({
            id: btoa(this.allmsg.id),
            paytype: 10,
          }),
        },
      });
    },
    /**获取年龄 */
    getAge(msg) {
       
      var birthday = new Date(msg.replace(/-/g, "/"));
      var d = new Date();
      var age =
        d.getFullYear() -
        birthday.getFullYear() -
        (d.getMonth() < birthday.getMonth() ||
        (d.getMonth() == birthday.getMonth() &&
          d.getDate() < birthday.getDate())
          ? 1
          : 0);
      return age;
    },
    selectshezhi(msg) {
       
      this.rulizhi(msg.entry_status)
    },
    //入离职
    rulizhi(status) {
      this.$http
        .post("/firm/v1/profile/status", {
          reqType: "profile",
          status: status, //2:入职 3：离职
          id: atob(this.$route.query.userid),
        })
        .then((res) => {
           
          this.$toast(JSON.parse(res.data).msg);
          this.$router.go(0);
        });
    },
    jimeth(index) {
      this.skillindex = index;
      this.jiindex = index;
    },
  },
};
</script>
<style scoped>
.addji {
  margin-left: 0.13rem;
}
.jnclat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.33rem;
  
}
.jnclat > :nth-child(2) {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  line-height: 0.48rem;
  color: #006aff;
}
.scc > :last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.gw2_1 {
  width: 3.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mocla {
  font-size: 0.3rem;
  line-height: 0.48rem;
  color: #ff5f19;
}
.duana2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0.29rem;
}
.ddiv1 {
  padding: 0.2rem 0.5rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5f19;
  line-height: 0.4rem;
  background: #ffffff;
  border-radius: 0.4rem;
  border: 0.02rem solid #ff5f19;
  text-align: center;
  margin-bottom: 0.24rem;
  margin-right: 0.24rem;
}
.ddiv2 {
  padding: 0.2rem 0.5rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgb(51, 51, 51);
  line-height: 0.4rem;
  background: #ebebeb;
  border-radius: 0.4rem;
  border: 0.02rem solid #ebebeb;
  text-align: center;
  margin-bottom: 0.24rem;
  margin-right: 0.24rem;
}
.jin {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9d9d9d;
  line-height: 0.42rem;
  margin-top: 0.46rem;
  margin-bottom: 0.42rem;
}
.jin > :nth-child(2) {
  margin: 0 0.64rem;
}
.selcl {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.45rem;
}
.selcl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.08rem;
  background-color: red;
  margin-left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(270deg, #fc9c45 0%, #fe4e04 100%);
  border-radius: 0.06rem;
  margin-top: 0.1rem;
}
.nomsgclass {
  height: 0.3rem;
}
.faqi {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 90%;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  margin-left: 5%;
}
.timeclass {
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #9d9d9d;
  line-height: 0.48rem;
  white-space: nowrap;
}

.jingcla {
  padding: 0.47rem 0;
  margin: 0 0.29rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.jingcla > :first-child {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #313131;
  line-height: 0.48rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.jingcla > :nth-child(2) {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #696969;
  line-height: 0.48rem;
  margin-top: 0.31rem;
}
.jingl {
  padding-top: 0.41rem;
}
.allgw {
  padding: 0.4rem 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.gw2 {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.48rem;
  margin: 0.3rem 0;
  margin-left: 0.29rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.gw3 {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #696969;
  line-height: 0.48rem;
  margin-left: 0.29rem;
}
.gw1 {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
}
.gw1 > :first-child {
  width: 0.09rem;
  height: 0.09rem;
  border-radius: 50%;
  background-color: #ff4900;
  margin-right: 0.17rem;
}
.zhicla {
  padding: 0.35rem 0;
  padding: 0.33rem 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.imgcc {
  display: flex;
  align-items: center;
}
.imgcc > div {
  margin-right: 0.5rem;
}
.imgcc img {
  width: 0.3rem;
  margin-right: 0.17rem;
}

.zhicla > :first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.4rem;
}
.zhicla > :nth-child(2) {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
  margin-top: 0.36rem;
}
.mmc {
  margin: 0 0.38rem;
}
.userclass {
  width: 1.27rem;
  height: 1.27rem;
  overflow: hidden;
  border-radius: 50%;
}
.userclass img {
  width: 100%;
  height: 100%;
}
.phmsg {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6f6f6f;
  line-height: 0.4rem;
  margin-top: 0.07rem;
}
.phmsg > :nth-child(2) {
  margin: 0 0.08rem;
}
.pms {
  display: flex;
  align-items: center;
}
.pms > :first-child {
  font-size: 0.46rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.65rem;
}
.pms > :nth-child(2) {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  padding: 0.05rem 0.1rem;
  background: #00beff;
  border-radius: 0.1rem;
  margin: 0 0.16rem;
}
.pms > :nth-child(3) {
  width: 0.4rem;
}
.classmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.33rem 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.sz {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
